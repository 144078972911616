/* 葡萄牙语 */
export default {
  emailRegister: 'Registrar Email',
  registerNotice:'Contas não registradas são automaticamente registradas e logadas',
  enterEmail: 'Por favor, insira seu endereço de email',
  enterCode: 'Por favor, insira seu código',
  getCode: 'Obter código',
  signIn: 'Entrar agora',
  signPropt: 'Ao fazer login, você concorda com a',
  policy: 'Política de Privacidade',
  agreement: 'Contrato de Usuário',
  emailRuleError: 'O formato do email está incorreto',

  starcard: `Benefícios do Membro Starcard`,
  more: `Mais`,
  describe: `A Starcard é a prova de benefícios do sistema de promoção Jubao Pen. Apenas aqueles que possuem uma Starcard podem desfrutar de uma série de benefícios e vantagens do sistema de promoção Jubao Pen. Existem Starcards de Experiência e Starcards Exclusivas. Usuários de Starcard podem receber recompensas em dinheiro.`,
  starReward: `Recompensas de Promoção Starcard`,
  unlock: `Desbloquear`,
  text1: `· Recompensa de Convite: Convide diretamente ou indiretamente usuários para comprar a Starcard e receba uma recompensa de 10% e 5%, respectivamente.`,
  text2: `· Recompensa de Promoção de Investimento em Direitos Autorais: Convide diretamente ou indiretamente usuários para investir na promoção de direitos autorais e receba uma recompensa de 10% e 5% dos rendimentos diários.`,
  text3: `· Recompensa de Visualização: Convide diretamente usuários para desbloquear um episódio e receba uma recompensa de 40%, ou assine a assinatura da série curta e receba uma recompensa de 20%.`,
  starClu: `Recompensas Star Group`,
  text4: `Para obter detalhes das regras de recompensa, consulte o atendimento ao cliente oficial ou a pessoa que o convidou.`,
  copyright: `Crowdfunding de Direitos Autorais`,
  td1: `Taxa de Retorno Anualizada`,
  td2: `Valor Inicial de Investimento`,
  td3: `Concluído/Total`,
  warning: `*Com base no efeito real da promoção, os rendimentos flutuarão diariamente.`,
  schedule: `Progresso do Crowdfunding`,
  buynow: `Compre Agora`,
  download: `Download`,
  go: `Ir para`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Características`,
  Participation: `Valor de Participação`,
  method: `Método de Pagamento`,
  amount: `Valor do Pagamento`,
  jump: `Redirecionar para Pagamento`,
  name1: `Séries Curtas`,
  name2: `Vários Tipos`,
  name3: `Membros Starcard`,
  name4: `Renda de Pipeline`,
  describe1: `Garantia de Qualidade`,
  describe2: `Pensando em Você`,
  describe3: `Subsídio de Bilhões`,
  describe4: `Principal Atividade Secundária`,
  warning1: `O conteúdo de entrada não pode estar vazio`,
  success1: `Vinculação Bem-sucedida`,
  ends: `Contagem Regressiva Encerrada`,
  warning2: `Você não está logado, por favor faça login primeiro`,
};
